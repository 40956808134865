require("./bootstrap");
require("./accent_fold");

import Vue from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue";
import Multiselect from "vue-multiselect";

Vue.component("Multiselect", Multiselect);

const el = document.getElementById("app");

if (el) {
    createInertiaApp({
        resolve: (name) => require(`./Pages/${name}`),
        setup({ el, App, props }) {
            new Vue({
                render: (h) => h(App, props),
            }).$mount(el);
        },
    });
}
