<template>
  <div>
    <div v-if="loadingTasks">
      <i class="fa fa-spinner fa-spin"></i>
      Chargement du détail du groupe...
    </div>
    <table v-else class="table table-sm mt-4">
      <thead>
        <tr>
          <th scope="col">
            <input
              type="checkbox"
              :checked="allProjectsSelected"
              @change="toggleFullSelection"
            />
          </th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <project-selector
        v-for="projectMapping in project.project_mappings"
        :key="projectMapping.id"
        :value="getSelectedTaskStatus(projectMapping)"
        :project-id="projectMapping.harvest_project_id"
        :project-name="getSelectedProjectName(projectMapping)"
        :task-id="projectMapping.harvest_task_id"
        @change="updateTaskSelection(projectMapping)"
      />
    </table>
  </div>
</template>

<script>
import ProjectSelector from "./ProjectSelector.vue";
export default {
  components: { ProjectSelector },
  props: {
    project: Object,
    harvestProjects: Array,
    value: Array,
  },
  data() {
    return {
      loadingTasks: true,
      selectedTasks: this.value,
      taskPerProject: [],
    };
  },
  computed: {
    allProjectsSelected() {
      return this.selectedTasks.length === this.project.project_mappings.length;
    },
  },
  created() {
    this.selectedTasks = [...this.project.project_mappings];
    this.project.project_mappings.forEach((x) => {
      fetch("/api/user/projects/" + x.harvest_project_id + "/tasks")
        .then((res) => res.json())
        .then((res) => {
          const availableTasks = res
            .map((x) =>
              x.task ? x.task : x.task_assignments.map((y) => y.task)
            )
            .sort((a, b) => a.name.localeCompare(b.name));
          this.taskPerProject.push({
            project_id: x.harvest_project_id,
            tasks: availableTasks,
          });
        })
        .catch(function (e) {
          console.error(e);
        })
        .finally(() => (this.loadingTasks = false));
    });
  },
  methods: {
    getSelectedProjectName(projectMapping) {
      const harvestProject = this.harvestProjects.find((x) => {
        if (x.project) {
          return x.project.id == projectMapping.harvest_project_id;
        }
        return x.id == projectMapping.harvest_project_id;
      });

      const projectTasks = this.taskPerProject.find(
        (x) => x.project_id == projectMapping.harvest_project_id
      );

      if (harvestProject && projectTasks) {
        const task = projectTasks.tasks.find(
          (x) => x.id == projectMapping.harvest_task_id
        );

        if (task) {
          return (
            harvestProject.client.name +
            " - " +
            (harvestProject.name ?? harvestProject.project.name) +
            " - " +
            task.name
          );
        }
      }
    },
    getSelectedTaskStatus(projectMapping) {
      const index = this.selectedTasks.indexOf(projectMapping);
      return index !== -1;
    },
    updateTaskSelection(projectMapping) {
      const index = this.selectedTasks.indexOf(projectMapping);
      if (index === -1) {
        this.selectedTasks.push(projectMapping);
      } else {
        this.selectedTasks.splice(index, 1);
      }
      this.$emit("input", this.selectedTasks);
    },
    toggleFullSelection() {
      if (this.allProjectsSelected) {
        this.selectedTasks.splice(0, this.selectedTasks.length);
        this.$emit("input", this.selectedTasks);
      } else {
        this.selectedTasks.splice(0, this.selectedTasks.length);
        this.selectedTasks.push(...this.project.project_mappings);
        this.$emit("input", this.selectedTasks);
      }
    },
  },
};
</script>
