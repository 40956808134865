<template>
  <div class="card">
    <div class="card-header d-flex mb-3">
      <h2 class="d-inline-block me-auto">{{ pageTitle }}</h2>
      <project-buttons
        :back-button-url="projectListUrl"
        class="my-auto"
        @save="persistTasks()"
      />
    </div>

    <div class="card-body">
      <div class="mb-3">
        <span class="badge rounded-pill bg-primary align-text-bottom">1</span>
        <label class="form-label" for="projectName">Nom du groupe</label>
        <input
          id="projectName"
          v-model="project.name"
          name="project_name"
          class="form-control"
          required
        />
      </div>

      <div class="mb-3">
        <span class="badge rounded-pill bg-primary align-text-bottom">2</span>
        <label class="form-label" for="projectName"
          >Description du groupe (optionnel)</label
        >
        <input
          id="projectDescription"
          v-model="project.description"
          name="project_description"
          class="form-control"
          required
        />
      </div>

      <div class="mb-3">
        <span class="badge rounded-pill bg-primary align-text-bottom">3</span>
        <label class="form-label" for="projectList"
          >Sélection des projets Harvest</label
        >
        <multiselect
          id="projectList"
          :value="selectedProjects"
          :options="filteredGroupedProjects"
          :multiple="true"
          :internal-search="false"
          group-label="label"
          group-values="projects"
          label="label"
          track-by="id"
          @select="selectProject"
          @search-change="searchThroughProjects"
        >
          <template slot="tag" slot-scope="props">
            <span class="multiselect__tag">
              <span>{{ getProjectName(props.option.id) }}</span>
              <i
                aria-hidden="true"
                tabindex="1"
                class="multiselect__tag-icon"
                @keypress.enter.prevent="removeProject(props.option.id)"
                @mousedown.prevent="removeProject(props.option.id)"
              ></i>
            </span>
          </template>
        </multiselect>
        <small class="form-text text-muted"
          >Veuillez sélectionner les projets Harvest à inclure dans le groupe de
          tâches.</small
        >
      </div>

      <project-tasks
        :projects="selectedProjectsWithGroupName"
        :tasks="selectedTasks"
        @select="selectProjectTask"
        @remove="removeProjectTask"
      ></project-tasks>
    </div>

    <div class="card-footer text-end">
      <project-buttons
        :back-button-url="projectListUrl"
        class="my-auto"
        @save="persistTasks()"
      />
    </div>
  </div>
</template>

<script>
import ProjectButtons from "../Components/ProjectButtons.vue";
import ProjectTasks from "../Components/ProjectTasks.vue";

export default {
  components: { ProjectTasks, ProjectButtons },
  props: {
    isNewProject: Boolean,
    saveProjectUrl: String,
    projectListUrl: String,
    groupedProjects: Array,
    project: Object,
    selectedProjectIds: Array,
    tasksPerProject: Array,
  },
  data() {
    return {
      projectIds: this.selectedProjectIds,
      selectedTasks: [],
      projectFilter: "",
    };
  },
  computed: {
    pageTitle() {
      return this.isNewProject
        ? "Nouveau groupe de tâches"
        : "Édition du groupe de tâches";
    },
    selectedProjects() {
      const selection = [];
      for (const element of this.groupedProjects.values()) {
        const projects = element.projects.filter((x) =>
          this.projectIds.includes(x.id.toString())
        );
        selection.push(...projects);
      }
      return selection;
    },
    selectedProjectsWithGroupName() {
      const projects = this.projectIds.map((x) => {
        const id = parseInt(x);
        return {
          id: id,
          name: this.getProjectName(id),
        };
      });

      return projects;
    },
    filteredGroupedProjects() {
      return this.groupedProjects.filter((x) => {
        const isQueryTextFound =
          this.accent_fold(x.label)
            .toLowerCase()
            .includes(this.projectFilter) ||
          x.projects.find((y) =>
            this.accent_fold(y.label).toLowerCase().includes(this.projectFilter)
          );

        return !this.projectFilter || (this.projectFilter && isQueryTextFound);
      });
    },
  },
  created() {
    this.selectedTasks = this.tasksPerProject.map((x) => {
      return {
        project_id: parseInt(x.harvest_project_id),
        task_id: parseInt(x.harvest_task_id),
      };
    });
  },
  methods: {
    selectProject(item) {
      this.projectIds.push(item.id.toString());
    },
    searchThroughProjects(query) {
      this.projectFilter = this.accent_fold(query).toLowerCase();
    },
    removeProject(element) {
      const index = this.projectIds.indexOf(element.toString());
      if (index > -1) {
        this.projectIds.splice(index, 1);
        this.selectedTasks = this.selectedTasks.filter(
          (x) => x.project_id !== element
        );
      }
    },
    getProjectName(projectId) {
      for (const group of this.groupedProjects.values()) {
        const found = group.projects.filter((x) => x.id === projectId);
        if (found.length > 0) {
          return "[" + group.label + "] " + found[0].label;
        }
      }
      return projectId;
    },
    selectProjectTask(projectId, task) {
      this.selectedTasks.push({ project_id: projectId, task_id: task.id });
    },
    removeProjectTask(projectId, task) {
      const index = this.selectedTasks.findIndex((x) => {
        return x.project_id === projectId && x.task_id === task.id;
      });

      if (index > -1) {
        this.selectedTasks.splice(index, 1);
      }
    },
    persistTasks() {
      if (this.isNewProject) {
        this.$inertia.post(this.saveProjectUrl, {
          name: this.project.name,
          description: this.project.description,
          mappings: this.selectedTasks,
        });
      } else {
        this.$inertia.put(this.saveProjectUrl, {
          name: this.project.name,
          description: this.project.description,
          mappings: this.selectedTasks,
        });
      }
    },
  },
};
</script>
