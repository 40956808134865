<template>
  <div class="mb-3">
    <span class="badge rounded-pill bg-primary align-text-bottom">4</span>
    <label class="form-label">Sélection des tâches</label>
    <project-task-selector
      v-for="project in projects"
      :key="project.id"
      :project="project"
      :tasks="getProjectTasks(project.id)"
      class="form-group mb-3"
    />
    <small v-if="projects.length === 0" class="form-text text-muted">
      Veuillez sélectionner des projets à l'étape #2.
    </small>
  </div>
</template>

<script>
import ProjectTaskSelector from "../Components/ProjectTaskSelector.vue";

export default {
  components: { ProjectTaskSelector },
  props: {
    projects: Array,
    tasks: Array,
  },
  methods: {
    getProjectTasks(projectId) {
      return this.tasks
        .filter((x) => x.project_id === projectId)
        .map((x) => x.task_id);
    },
  },
};
</script>
