<template>
  <div>
    <a href="#" class="btn btn-success btn-sm" @click="$emit('save')">
      <i class="fa fa-save"></i>
      Sauvegarder
    </a>
    <a :href="backButtonUrl" class="btn btn-sm btn-secondary">
      <i class="fa fa-arrow-left"></i>
      Retour à la liste
    </a>
  </div>
</template>

<script>
export default {
  props: {
    backButtonUrl: String,
  },
};
</script>
