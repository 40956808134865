<template>
  <tr>
    <td>
      <input
        :id="'project-selector-' + projectId + '-' + taskId"
        :checked="value"
        type="checkbox"
        @input="$emit('change')"
      />
    </td>
    <td>
      <label :for="'project-selector-' + projectId + '-' + taskId">
        {{ projectNameWithFallback }}
      </label>
    </td>
    <td>
      <span v-if="!projectName">
        <i class="fa fa-exclamation-triangle text-warning small"></i>
        <span class="small text-small"
          >This project is not assigned to you</span
        >
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    projectName: String,
    projectId: String,
    taskId: String,
    loading: Boolean,
    value: Boolean,
  },
  computed: {
    projectNameWithFallback() {
      if (this.projectName) {
        return this.projectName;
      }
      return `Projet ${this.projectId}`;
    },
  },
};
</script>
