<template>
  <div>
    <label :for="'project-tasks-' + project.id" class="fw-bold">
      {{ project.name }}
      <i v-if="loadingTasks" class="fa fa-spinner fa-spin"></i>
    </label>
    <multiselect
      :id="'project-tasks-' + project.id"
      class="d-inline-block"
      :value="selectedTasks"
      :options="availableTasks"
      :multiple="true"
      track-by="id"
      label="name"
      @select="$parent.$emit('select', project.id, $event)"
      @remove="$parent.$emit('remove', project.id, $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    project: Object,
    tasks: Array,
  },
  data() {
    return {
      loadingTasks: true,
      availableTasks: [],
    };
  },
  computed: {
    selectedTasks() {
      return this.availableTasks.filter((x) => this.tasks.includes(x.id));
    },
  },
  created() {
    fetch("/api/projects/" + this.project.id + "/tasks")
      .then((res) => res.json())
      .then((res) => {
        this.availableTasks = res
          .map((x) => x.task)
          .sort((a, b) => a.name.localeCompare(b.name));
      })
      .then(() => (this.loadingTasks = false))
      .catch(function (e) {
        console.error(e);
      });
  },
};
</script>
