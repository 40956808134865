<template>
  <div class="card">
    <div class="card-header">
      <h2>TimeSplit</h2>
    </div>
    <div class="card-body">
      <div
        v-if="splitTimeId"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <i :class="['me-2 fad fa', getRandomSuccessIcon()]"></i>
        Vos nouvelles entrées de temps ont été créées avec succès!
        <button
          type="button"
          class="btn btn-link p-0 pb-1"
          @click="revertTimeSplit()"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          @click="splitTimeId = null"
        />
      </div>

      <div
        v-if="flash.message"
        class="alert alert-success"
        v-html="flash.message"
      ></div>

      <div
        v-if="flash.warning"
        class="alert alert-warning"
        v-html="flash.warning"
      ></div>

      <div v-if="flash.error" class="alert alert-danger">
        Une erreur s'est produite: {{ flash.error }}
      </div>

      <div class="row">
        <div class="mb-3 col-md-6">
          <label class="form-label" for="date">Date</label>
          <input
            id="date"
            v-model="splitDate"
            type="date"
            name="date"
            class="form-control"
          />
        </div>

        <div class="mb-3 col-md-6">
          <label class="form-label" for="time">Temps à diviser</label>
          <div class="d-flex">
            <input
              id="time"
              v-model="form.time"
              type="number"
              step="0.1"
              min="0"
              name="time"
              class="form-control"
              required
            />
            <select
              v-model="form.unit"
              name="unit"
              class="form-control w-25 ms-3"
            >
              <option value="hours">Heure(s)</option>
              <option value="minutes">Minute(s)</option>
            </select>
          </div>
          <div v-if="form.errors.time" class="text-danger small">
            {{ form.errors.time }}
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="description">Description</label>
        <textarea
          id="description"
          v-model="form.description"
          name="description"
          class="form-control"
        ></textarea>
        <div v-if="form.errors.description" class="text-danger small">
          {{ form.errors.description }}
        </div>
      </div>

      <div class="mb-3">
        <label class="form-label" for="projectId">Groupe de tâches</label>
        <multiselect
          id="projectId"
          v-model="selectedProject"
          :options="projects"
          label="name"
          track-by="id"
          placeholder="Veuillez sélectionner un groupe"
        >
          <template slot="singleLabel" slot-scope="props">
            {{ getGroupLabel(props) }}
          </template>
          <template slot="option" slot-scope="props">
            {{ getGroupLabel(props) }}
          </template>
        </multiselect>
        <div v-if="form.errors.project_id" class="text-danger small">
          {{ form.errors.project_id }}
        </div>

        <div v-if="selectedProject" id="individualTasksSelection">
          <span class="small">
            <a href="#" @click="toggleIndividualTaskSelection()">
              <span v-if="!showIndividualTaskSelector" id="selectionMessage"
                >Sélectionner les tâches individuellement</span
              >
              <span v-if="showIndividualTaskSelector" id="cancelMessage"
                >Annuler la sélection individuelle</span
              >
            </a>
          </span>

          <div v-if="showIndividualTaskSelector" id="tasksSelection">
            <invidivual-task-selector
              v-model="selectedTasks"
              :project="selectedProject"
              :harvest-projects="harvestProjects"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <button
        type="submit"
        class="btn btn-success"
        :disabled="saving || !selectionMade"
        @click="saveTimeEntries"
      >
        <i v-if="saving" class="fa fa-spinner fa-spin"></i>
        Soumettre
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import InvidivualTaskSelector from "../Components/InvidivualTaskSelector.vue";

export default {
  components: { InvidivualTaskSelector },
  props: {
    errors: Object,
    error: String,
    flash: Object,
    projects: Array,
    harvestProjects: Array,
    successIcons: Array,
  },
  data() {
    return {
      showIndividualTaskSelector: false,
      selectedTasks: [],
      selectedProject: null,
      saving: false,
      splitTimeId: null,
      form: this.$inertia.form({
        date: dayjs().format("YYYY-MM-DD"),
        time: null,
        unit: "hours",
        description: "",
      }),
    };
  },
  computed: {
    splitDate: {
      get() {
        return this.form.date;
      },
      set(newDate) {
        this.form.date = newDate;
      },
    },
    selectionMade() {
      return this.tasksSelected && this.form.description && this.form.time;
    },
    tasksSelected() {
      return (
        this.selectedProject &&
        ((this.showIndividualTaskSelector && this.selectedTasks.length !== 0) ||
          !this.showIndividualTaskSelector)
      );
    },
  },
  watch: {
    selectedProject(newValue) {
      if (newValue && this.showIndividualTaskSelector) {
        this.showIndividualTaskSelector = false;
      }
    },
  },
  methods: {
    getGroupLabel(props) {
      const option = props.option;
      return `${option.name} (${option.project_mappings.length} ${
        option.project_mappings.length > 1 ? "tâches" : "tâche"
      })`;
    },
    toggleIndividualTaskSelection() {
      this.showIndividualTaskSelector = !this.showIndividualTaskSelector;
      if (this.showIndividualTaskSelector) {
        this.updateSelectedTasks();
      } else {
        this.resetSelectedTasks();
      }
    },
    updateSelectedTasks() {
      this.selectedTasks.push(...this.selectedProject.project_mappings);
    },
    resetSelectedTasks() {
      this.selectedTasks.splice(0, this.selectedTasks.length - 1);
    },
    saveTimeEntries() {
      this.saving = true;
      this.splitTimeId = null;

      this.form
        .transform((data) => ({
          ...data,
          project_id: this.selectedProject ? this.selectedProject.id : null,
          tasks: this.selectedTasks.map((x) => ({
            project_id: x.harvest_project_id,
            task_id: x.harvest_task_id,
          })),
        }))
        .post("/timesplit", {
          resetOnSuccess: false,
          onSuccess: () => {
            this.form.reset();
            this.selectedProject = null;
            this.selectedTasks = [];
            this.splitTimeId = this.flash.success;
          },
          onFinish: () => (this.saving = false),
        });
    },
    getRandomSuccessIcon() {
      return (
        "fa-" +
        this.successIcons[(this.successIcons.length * Math.random()) | 0]
      );
    },
    revertTimeSplit() {
      this.$inertia.delete("/timesplit/" + this.splitTimeId, {
        onSuccess: () => (this.splitTimeId = null),
      });
    },
  },
};
</script>
