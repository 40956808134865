<template>
  <div class="card">
    <div class="card-header">
      <h2>Temps compilé par fonctionnalité</h2>
    </div>
    <div class="card-body">
      <div class="mb-4">
        <multiselect
          id="projectId"
          v-model="project"
          :options="groupedProjects"
          group-label="label"
          group-values="projects"
          label="label"
          class="mb-3"
          placeholder="Veuillez sélectionner un projet"
        />

        <div class="mb-3">
          <label class="from-label me-2" for="fromDate">Date de début</label>
          <input
            id="fromDate"
            v-model="from"
            class="form-control"
            type="date"
          />
        </div>

        <div class="mb-3">
          <label class="form-label me-2" for="toDate">Date de fin</label>
          <input
            id="toDate"
            v-model="to"
            class="form-control me-2"
            type="date"
          />
        </div>

        <button
          :disabled="!project"
          class="btn btn-primary form-control"
          @click="loadData()"
        >
          Rechercher
        </button>
      </div>

      <p v-if="searchPerformed && features.length === 0">
        Aucun résultat à afficher pour la période du {{ from }} au {{ to }}.
      </p>

      <table
        v-if="searchPerformed && features.length > 0"
        class="table table-striped"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Fonctionnalité</th>
            <th class="text-center">Temps estimé (heures)</th>
            <th class="text-center">Nombre d'heures</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="feature in features" :key="feature.id">
            <td>{{ feature.id }}</td>
            <td>{{ getFeatureName(feature.id) }}</td>
            <td class="text-center">{{ getFeatureEstimate(feature.id) }}</td>
            <td class="text-center">
              {{
                feature.time.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}

              <template v-if="estimates[feature.id.toString()]">
                <span
                  v-if="planningDiff(feature) > 0"
                  class="small text-success"
                >
                  <i class="fa fa-arrow-down"></i>
                  {{
                    planningDiff(feature).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
                <span v-else class="small text-danger">
                  <i class="fa fa-arrow-up"></i>
                  {{
                    planningDiff(feature).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </template>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="fw-bold">
            <td>Total</td>
            <td />
            <td class="text-center">{{ estimatesTotal }}</td>
            <td class="text-center">
              {{
                featuresTotal.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })
              }}

              <template v-if="estimatesTotal > 0">
                <span v-if="diffTotal > 0" class="small text-success">
                  <i class="fa fa-arrow-down"></i>
                  {{
                    diffTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
                <span v-else class="small text-danger">
                  <i class="fa fa-arrow-up"></i>
                  {{
                    diffTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </template>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    harvestProjects: Array,
    groupedProjects: Array,
  },
  data() {
    return {
      searchPerformed: false,
      data: null,
      project: null,
      client: null,
      from: null,
      to: null,
    };
  },
  computed: {
    features() {
      const results = [];
      if (this.data && this.data.time_entries.length) {
        this.data.time_entries.forEach((x) => {
          const found = x.notes && x.notes.match(/\!([0-9]+)/);
          if (found) {
            const featureId = found[1];
            const featureIndex = results.findIndex((y) => y.id === featureId);
            if (featureIndex !== -1) {
              results[featureIndex].time += x.hours;
            } else {
              results.push({ id: found[1], time: x.hours });
            }
          }
        });

        results.sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
      }
      return results;
    },
    estimates() {
      return this.data && this.data.estimates ? this.data.estimates : [];
    },
    estimatesTotal() {
      const features = this.features;
      let sum = 0;

      for (const [key, item] of Object.entries(this.estimates)) {
        const feature = features.find((x) => x.id == key);
        if (feature) {
          sum += item.value;
        }
      }

      return sum;
    },
    featuresTotal() {
      return this.features.reduce((sum, current) => sum + current.time, 0);
    },
    diffTotal() {
      return this.estimatesTotal - this.featuresTotal;
    },
  },
  watch: {
    project() {
      this.searchPerformed = false;
    },
    from() {
      this.searchPerformed = false;
    },
    to() {
      this.searchPerformed = false;
    },
  },
  created() {
    this.from = dayjs().format("YYYY-MM-DD");
    this.to = dayjs().format("YYYY-MM-DD");
  },
  methods: {
    loadData() {
      fetch(
        `/api/time-per-feature?project_id=${this.project.id}&from=${this.from}&to=${this.to}`
      )
        .then((res) => res.json())
        .then((res) => {
          this.data = res;
        })
        .catch(function (e) {
          console.error(e);
        })
        .finally(() => (this.searchPerformed = true));
    },
    planningDiff(feature) {
      return this.estimates[feature.id.toString()].value - feature.time;
    },
    getFeatureName(featureId) {
      const feature = this.estimates[featureId.toString()];
      if (feature) {
        return feature.name;
      }
      return "";
    },
    getFeatureEstimate(featureId) {
      const feature = this.estimates[featureId.toString()];
      if (feature) {
        return feature.value;
      }
      return 0;
    },
  },
};
</script>
